import { Link as RemixLink } from "@remix-run/react";
import { useMemo, type ReactElement, type ReactNode } from "react";
import "./Link.css";

export interface LinkProps {
  to: string;
  className?: string;
  children: ReactNode;
}

function InternalLink(props: LinkProps): ReactElement {
  const { to, className, children } = props;

  return (
    <RemixLink to={to} className={className}>
      {children}
    </RemixLink>
  );
}

function ExternalLink(props: LinkProps): ReactElement {
  const { to, className, children } = props;

  return (
    <a
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

function SmartLink(props: LinkProps): ReactElement {
  const { to } = props;

  const isExternal = useMemo(
    () => new URL(to, "https://__example__.com").hostname !== "__example__.com",
    [to]
  );

  return isExternal ? <ExternalLink {...props} /> : <InternalLink {...props} />;
}

export default {
  Internal: InternalLink,
  External: ExternalLink,
  Smart: SmartLink,
};
